import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class WeatherContainer extends PureComponent {
  render () {
    const {
      widget: {
        idPrefix = '',
        name
      }
    } = this.props

    return (
      <div id={`${idPrefix}widget_${name}_container`}/>
    )
  }
}

WeatherContainer.propTypes = {
  widget: PropTypes.object.isRequired
}

export default WeatherContainer
