import React, { createRef } from 'react'
import { hot } from 'react-hot-loader'
import WeatherContainer from '../components/widgets/WeatherContainer'
import { LAYOUT_SECTIONS, WIDGETS } from '../constants'
import LayoutUI from './LayoutUI'
import LayoutHeadless from './LayoutHeadless'
import EmailLink from '../components/widgets/Header/EmailLink'
import { EmailProvider } from '../context/Email'

class Pocasi extends LayoutHeadless {
  constructor (props) {
    super(props)

    this.loginModalRef = createRef()
    this.userMenuRef = createRef()
  }

  componentDidMount () {
    super.componentDidMount()
    this.updateElements(this.loginModalRef, this.userMenuRef)
  }

  componentDidUpdate () {
    this.updateElements(this.loginModalRef, this.userMenuRef)
  }

  getDynamicWidgets () {
    const {
      widgets
    } = this.props

    return Object.assign({}, super.getDynamicWidgets(), {
      [WIDGETS.WEATHER.NOTIFY]: () => <WeatherContainer widget={widgets.notify}/>,
      [WIDGETS.WEATHER.WELCOME]: () => <WeatherContainer widget={widgets.welcome}/>,
      [WIDGETS.WEATHER.VIDEO]: () => <WeatherContainer widget={widgets.video}/>,
      [WIDGETS.WEATHER.FORECAST]: () => <WeatherContainer widget={widgets.forecast}/>,
      [WIDGETS.WEATHER.SEASON]: () => <WeatherContainer widget={widgets.season}/>,
      [WIDGETS.WEATHER.CITIES]: () => <WeatherContainer widget={widgets.cities}/>,
      [WIDGETS.WEATHER.ALLERGY]: () => <WeatherContainer widget={widgets.allergy}/>,
      [WIDGETS.WEATHER.WELCOME_DETAIL]: () => <WeatherContainer widget={widgets.welcomedetail}/>,
      [WIDGETS.WEATHER.SUNRISE]: () => <WeatherContainer widget={widgets.sunrise}/>,
      [WIDGETS.COMMON.EMAIL_LINK]: () => <EmailLink widget={widgets.emaillink} forceShowEmailLink/>
    })
  }

  getDesktopWidgetsLayout () {
    return {
      [LAYOUT_SECTIONS.TOP]: [
        WIDGETS.COMMON.LEADERBOARD
      ],
      [LAYOUT_SECTIONS.HEADER]: [
        WIDGETS.COMMON.LOGO,
        WIDGETS.COMMON.SEARCH,
        WIDGETS.COMMON.EMAIL_LINK
      ],
      [LAYOUT_SECTIONS.OVER_CONTENT]: [
        WIDGETS.WEATHER.NOTIFY
      ],
      [LAYOUT_SECTIONS.LEFT_COLUMN]: [
        WIDGETS.WEATHER.WELCOME,
        WIDGETS.WEATHER.VIDEO,
        WIDGETS.WEATHER.FORECAST,
        WIDGETS.WEATHER.SEASON,
        WIDGETS.WEATHER.CITIES,
        WIDGETS.WEATHER.ALLERGY
      ],
      [LAYOUT_SECTIONS.RIGHT_COLUMN]: [
        WIDGETS.WEATHER.WELCOME_DETAIL,
        WIDGETS.COMMON.RIGHT_BANNER,
        WIDGETS.WEATHER.SUNRISE,
        WIDGETS.COMMON.HALF_PAGE
      ],
      [LAYOUT_SECTIONS.BOTTOM]: [],
      [LAYOUT_SECTIONS.OUTSIDE_CONTENT]: []
    }
  }

  getTabletWidgetsLayout () {
    return {
      [LAYOUT_SECTIONS.TOP]: [
        WIDGETS.COMMON.STRIP
      ],
      [LAYOUT_SECTIONS.HEADER]: [
        WIDGETS.COMMON.LOGO,
        WIDGETS.COMMON.SEARCH
      ],
      [LAYOUT_SECTIONS.OVER_CONTENT]: [
        WIDGETS.WEATHER.NOTIFY
      ],
      [LAYOUT_SECTIONS.LEFT_COLUMN]: [
        WIDGETS.WEATHER.WELCOME,
        WIDGETS.WEATHER.VIDEO,
        WIDGETS.WEATHER.FORECAST,
        WIDGETS.WEATHER.SUNRISE,
        WIDGETS.COMMON.SMART_ADVERT_1,
        WIDGETS.WEATHER.SEASON,
        WIDGETS.WEATHER.CITIES,
        WIDGETS.WEATHER.ALLERGY,
        WIDGETS.COMMON.SMART_ADVERT_2
      ],
      [LAYOUT_SECTIONS.RIGHT_COLUMN]: [],
      [LAYOUT_SECTIONS.BOTTOM]: [],
      [LAYOUT_SECTIONS.OUTSIDE_CONTENT]: []
    }
  }

  getMobileWidgetsLayout () {
    return {
      [LAYOUT_SECTIONS.TOP]: [
        WIDGETS.COMMON.STRIP
      ],
      [LAYOUT_SECTIONS.HEADER]: [
        WIDGETS.COMMON.LOGO,
        WIDGETS.COMMON.SEARCH
      ],
      [LAYOUT_SECTIONS.OVER_CONTENT]: [
        WIDGETS.WEATHER.NOTIFY
      ],
      [LAYOUT_SECTIONS.LEFT_COLUMN]: [
        WIDGETS.WEATHER.WELCOME,
        WIDGETS.WEATHER.VIDEO,
        WIDGETS.WEATHER.FORECAST,
        WIDGETS.WEATHER.SUNRISE,
        WIDGETS.COMMON.SMART_ADVERT_1,
        WIDGETS.WEATHER.SEASON,
        WIDGETS.WEATHER.CITIES,
        WIDGETS.WEATHER.ALLERGY,
        WIDGETS.COMMON.SMART_ADVERT_2
      ],
      [LAYOUT_SECTIONS.RIGHT_COLUMN]: [],
      [LAYOUT_SECTIONS.BOTTOM]: [],
      [LAYOUT_SECTIONS.OUTSIDE_CONTENT]: [
        WIDGETS.COMMON.SMART_ADVERT_4
      ]
    }
  }

  render () {
    const {
      layout,
      widgets,
      widgets: {
        config: {
          proxyOptions,
          cookies: cookieNameConstants,
          browserCookies,
          ecoIdentity: {
            userInfo: {
              reloadIntervalMs
            }
          }
        },
        layout: {
          meta: {
            inbox: {
              serviceUrlPattern,
              xhrLogoutUri,
              isTracked
            }
          }
        }
      }
    } = this.props

    const emailOptions = {
      proxyOptions,
      serviceUrlPattern,
      xhrLogoutUri,
      isTracked,
      cookieNameConstants,
      cookies: browserCookies,
      ecoIdentityReloadIntervalMs: reloadIntervalMs
    }

    return (
      <EmailProvider options={emailOptions}>
        <LayoutUI
          layout={layout}
          widgets={widgets}
          top={this.renderTop()}
          header={this.renderHeader()}
          overContent={this.renderOverContent()}
          leftColumn={this.renderLeftColumn()}
          rightColumn={this.renderRightColumn()}
          bottom={this.renderBottom()}
          outsideContent={this.renderOutsideContent()}
          googleAnalytics={this.googleAnalytics}
          loginModalRef={this.loginModalRef}
          userMenuRef={this.userMenuRef}
          onHeaderHeightChange={this.onHeaderHeightChange}
        />
      </EmailProvider>
    )
  }
}

export default hot(module)(Pocasi)
